import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import BodyClassName from 'react-body-classname';
import React, { useEffect, useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';

import iconUserAlt from '../images/icon-user-alt.svg';
import Map, { IndividualMap } from '../components/Map';
import { COUNTRIES } from '../components/Map/countryData';
import iconArrowLeft from '../images/icon-arrow-left.svg';
import iconWriteEmail from '../images/icon-write-email.svg';
import iconTwitterAlt from '../images/icon-twitter-alt.svg';
import iconArrowRight from '../images/icon-arrow-right.svg';
import iconFacebookAlt from '../images/icon-facebook-alt.svg';
import iconInstagramAlt from '../images/icon-instagram-alt.svg';

const TeamPage = (props) => {
  const {
    bgTeam,
    teamSingleMap,
    allPrismicTeamMembers: { nodes: prismicMembers },
  } = props.data;

  const [teammembers, setTeamMembers] = useState(prismicMembers);
  const [selectedMemberIdx, setSelectedMember] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [mapCategory, setMapCat] = useState(false);
  const [numFlags, setNFlags] = useState(1);

  console.log(teammembers)
  const selectedTeamMember = teammembers[selectedMemberIdx];
  const selectedTeamMemberData = selectedTeamMember?.data;

  const dataName = selectedTeamMemberData?.name[0]?.text;
  const dataFamilyOrigin = selectedTeamMemberData?.familyOrigin;
  const dataActionPhoto = selectedTeamMemberData?.actionPhoto?.url;
  const dataBirthCountry = selectedTeamMemberData?.birthCountry[0]?.text;
  const dataMinistryRole = selectedTeamMemberData?.ministryRole[0]?.text;
  const dataBio = selectedTeamMemberData?.bio;
  const dataEmail = selectedTeamMemberData?.email[0]?.text;
  const dataTwitter = selectedTeamMemberData?.twitter[0]?.text;
  const dataInstagram = selectedTeamMemberData?.instagram[0]?.text;
  const dataFacebook = selectedTeamMemberData?.facebook[0]?.text;

  // Currently `dataBio` is fetched as array of text.
  // Concat all text into one single descripton.
  const getBioDescription = () => {
    const dataFullBio = [];
    if (!!dataBio?.length) {
      dataBio.map((bio) => dataFullBio.push(bio.text));
      dataFullBio.join();

      return dataFullBio;
    }
    return '';
  };

  useEffect(() => {
    let numFlags = 0;
    if (!isNaN(selectedMemberIdx)) {
      numFlags = numFlags + selectedTeamMemberData?.familyOrigin?.length || 0;
    }
    if (numFlags === 2 || numFlags === 1) {
      setNFlags(1);
    } else {
      setNFlags(numFlags);
    }
  }, [selectedMemberIdx]);

  const setSelectedMemberIdx = (idx) => {
    setSelectedMember(idx);
    setMapCat(false);
    setShowPopup(idx !== null);
  };

  const setNextMember = () => {
    let nextIdx = selectedMemberIdx + 1;
    if (nextIdx === teammembers?.length) nextIdx = 0;
    setSelectedMemberIdx(nextIdx);
  };

  const setPrevMember = () => {
    let prevIdx = selectedMemberIdx - 1;
    if (selectedMemberIdx === 0) prevIdx = teammembers.length - 1;
    setSelectedMemberIdx(prevIdx);
  };

  return (
    <BodyClassName className="body-light page-team">
      <DefaultLayoutComponent title="Meet The Team" openGraphImage={''}>
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner bgImage"
            fluid={bgTeam.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="pd-x-md pb-container">
                <h3 className="pb-title">Meet The Team</h3>
              </div>
            </div>
          </BackgroundImage>
          <section className="the-team">
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="section bg-light team-container">
                <div className="wrapper wrapper-lg pd-x-md">
                  <div className="map-container">
                    <Map mappingList={teammembers} handleSelect={setSelectedMemberIdx} />
                  </div>
                  <div className="team-list columns d-flex">
                    {teammembers?.map((m, idx) => (
                      <div
                        key={m.id}
                        className="cols cols-3 list-member pd-x-0"
                        onClick={() => {
                          setSelectedMemberIdx(idx);
                        }}
                      >
                        <div className="list-member-info">
                          <div className="list-figure">
                            <LazyLoadImage src={m.data.portraitPhoto.url} alt={''} effect="blur" />
                            {m.data.actionPhoto.url && (
                              <LazyLoadImage
                                src={m.data.actionPhoto.url}
                                alt={''}
                                wrapperClassName="img-hover"
                                effect="blur"
                              />
                            )}
                          </div>
                          <div className="list-intro">
                            <span className="member-name">{m.data.name[0].text}</span>
                            <span className="member-post">{m.data.ministryRole[0].text}</span>
                            <div className="btn-meet">
                              <img src={iconUserAlt} alt="Icon User" /> Meet {m.data.name[0].text.split(' ')[0]}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className={`popup popup-center-xy popup-team ${showPopup ? 'active' : ''} ${selectedMemberIdx}`}>
          <div className="wrapper wrapper-xl center-xy pd-x-md">
            <div
              className="nav-bars"
              onClick={() => {
                setSelectedMemberIdx(null);
              }}
            >
              <div></div>
              <div></div>
            </div>
            {selectedMemberIdx !== null && selectedTeamMember && (
              <div className="team-card d-flex">
                <div className="popup-inner">
                  <div className="popup-map">
                    <IndividualMap member={selectedTeamMember} showBy={mapCategory ? 'familyOrigin' : 'birthCountry'} />
                    <BackgroundImage
                      className="cols team-single-map bgImage"
                      fluid={teamSingleMap.childImageSharp.fluid}
                    >
                      <div>
                        <div className={`switch bottom-${numFlags}`}>
                          <span className={`birth ${!mapCategory && 'active'}`}>My Birth</span>
                          <label className="btn-switch">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                setMapCat(!mapCategory);
                              }}
                              checked={mapCategory}
                            />
                            <span className="switch-slider"></span>
                          </label>
                          <span className={`family ${mapCategory && 'active'}`}>Family Lineage</span>
                        </div>
                        <div className="flag">
                          <div className={`flag-holder ${!mapCategory && 'active'}`}>
                            <img src={COUNTRIES[dataBirthCountry.toLowerCase()].flag.default} />
                            <p>{dataBirthCountry}</p>
                          </div>
                          <div className={'flag-team'}>
                            {dataFamilyOrigin?.length &&
                              dataFamilyOrigin.map((fo, id) => (
                                <div key={id} className={`flag-holder ${mapCategory && 'active'} border-top`}>
                                  <img src={COUNTRIES[fo.country[0].text.toLowerCase()].flag.default} />
                                  <p>{fo.country[0].text}</p>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </BackgroundImage>
                  </div>
                  <div className="cols team-single-info" style={{ flex: 1 }}>
                    <div className="info-head">
                      <div
                        className="tm-figure bgImage"
                        style={{
                          backgroundImage: `url(${dataActionPhoto})`,
                        }}
                      ></div>
                      <div className="tm-intro">
                        <span className="tm-name">{dataName}</span>
                        <span className="tm-post">{dataMinistryRole}</span>
                      </div>
                    </div>
                    <div className="info-body">
                      <p className="p-lg tm-info">{getBioDescription()}</p>
                    </div>
                    <div className="info-footer">
                      <div className="social-share">
                        <ul>
                          {dataFacebook && (
                            <li>
                              <a href={dataFacebook} target="_blank">
                                <img src={iconFacebookAlt} alt="Facebook" />
                              </a>
                            </li>
                          )}

                          {dataInstagram && (
                            <li>
                              <a href={dataInstagram} target="_blank">
                                <img src={iconInstagramAlt} alt="Instagram" />
                              </a>
                            </li>
                          )}

                          {dataTwitter && (
                            <li>
                              <a href={dataTwitter} target="_blank">
                                <img src={iconTwitterAlt} alt="Twitter" />
                              </a>
                            </li>
                          )}
                          {dataEmail && (
                            <li>
                              <a href={`mailto:${dataEmail}`}>
                                <img src={iconWriteEmail} alt="Email" />
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="paginate-buttons">
                        <div className="btn btn-md btn-nav" onClick={setPrevMember}>
                          <img src={iconArrowLeft} alt="" className="icon-arrow" /> Previous Person
                        </div>
                        <div className="btn btn-md btn-nav" onClick={setNextMember}>
                          Next Person <img src={iconArrowRight} alt="" className="icon-arrow" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="popup-overlay"
            onClick={() => {
              setSelectedMemberIdx(null);
            }}
          ></div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgTeam: file(relativePath: { eq: "bg-team.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    teamSingleMap: file(relativePath: { eq: "team-single-map.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allPrismicTeamMembers(sort: { fields: data___name___text }) {
      nodes {
        data {
          familyOrigin: family_origin {
            country {
              text
            }
          }
          portraitPhoto: portrait_photo {
            url
          }
          actionPhoto: action_photo {
            url
          }
          name {
            text
          }
          ministryRole: ministry_role {
            text
          }
          birthCountry: birth_country {
            text
          }
          bio {
            text
          }
          facebook {
            text
          }
          instagram {
            text
          }
          twitter {
            text
          }
          email {
            text
          }
          currentCountry: current_country {
            text
          }
        }
        id
      }
    }
  }
`;

export default TeamPage;
